(function($) {

	toggleNavigation = function() {
		$('.toggle--navigation').on('click', function(e){
			e.preventDefault();
			if($('body').hasClass('navigation--open')) {
				$('body').removeClass('navigation--open');
			}
			else {
				$('body').addClass('navigation--open');
			}
		}); 
	};


	closeNavigation = function() {
		$('.close--navigation').on('click', function(e){
			e.preventDefault();
			$('body').removeClass('navigation--open');
		}); 
	};


	stickyNavigation = function() {
		var scrollTop = 0;

		if ($(this).scrollTop() > scrollTop ){  
			$('body').addClass('header--sticky');
		}
		else {
			$('body').removeClass('header--sticky');
		}
	};


	/*----------  Open and close subnavigation on touch devices  ----------*/
	toggleSubNav = function() {
		var windowWidth = $(window).width();
		if(
			($('body').hasClass('device--touch') && windowWidth < 1024) || 
			windowWidth < 1024
		) {
			$('.toggle--subnav').show();
			$(".toggle--subnav").on('click', function(e){
				e.stopPropagation();
				if($(this).parent('li').hasClass('open--subnav')) {
					$(this).parent('li').removeClass('open--subnav');
					$(this).addClass('icon-add').removeClass('icon-min');
				}
				else {
					$(this).parent('li').siblings().removeClass('open--subnav');
					$(this).parent('li').siblings().find('.toggle--subnav').removeClass('icon-min').addClass('icon-add');
					$(this).parent('li').addClass('open--subnav');
					$(this).addClass('icon-min').removeClass('icon-add');
				}
			}); 
		} 
		else if (
			($('body').hasClass('device--notouch') && windowWidth > 1023 ) ||
			windowWidth > 1023
		) {
			$('.toggle--subnav').hide();
			$('.navigation li').removeClass('open--subnav');
		}
	};

})(jQuery); // Fully reference jQuery after this point.
