/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

 (function($) {

 	

 	openModal = function() {
 		$('.open--modal').magnificPopup({
 			type: 'inline',
 			preloader: false,
 		});
 	};

 	closeModal = function() {
 		$('.close--modal').on( "click", function(e) {
 			e.preventDefault();
 			$.magnificPopup.close();
 		});
 	};

 	scrollTop = function() {
 		$('.scroll--top').on('click', function(e){
 			e.preventDefault();
 			var $header = $('.header');
 			var offset = 0;
 			if ($(window).width() > 768) {
 				offSet = 45;
 			} else {
 				offSet = -17;
 			}
 			$('html,body').animate({
 				scrollTop: $(this).offset().top - $header.height() + offSet +  'px'
 			});
 		});
 	};

 	mailToPopup = function() {
 		$('.open--modal').on('click', function(e){
 			var mailto_name = $(this).data('name');
 			$('#mailto-name').html(mailto_name);
 			$('#input_3_1').val($(this).data('mail'));
 		});

 		$('.open--mailto').magnificPopup({
 			type: 'inline'
 		});
 	}



 	headerImage = function() {
 		$('.slider')
 		.slick({
 			dots: true,
 			customPaging : function(slider, i) {
 				return '<span></span>';
 			},
 			infinite: true,
 			slidesToShow: 1,
 			arrows: false,
 			lazyLoad: 'ondemand',
 			autoplay: true,
 			autoplaySpeed: 7000,
 			fade: true,
 			nextArrow: '<div class="slick-arrow slick-right icon-icon-arrow-right"></div>',
 			prevArrow: '<div class="slick-arrow slick-left icon-icon-arrow-left"></div>',
 			slidesToScroll: 1,
 			adaptiveHeight: false,
 			cssEase: 'linear'
 		})
 		.show();
 	};

 	
 	toggleSidebarDropdown = function() {
 		$('.sidebar__selection .selected').on('click', function(e){
 			e.preventDefault();
 			$('.sidebar__selection').toggleClass('sidebar__selection--open');
 		});
 	}


 	/*----------  On scroll  ----------*/
 	$(window).scroll(function() {
 		stickyNavigation();
 	});

 	var waitForFinalEvent = (function () {
 		var timers = {};
 		return function (callback, ms, uniqueId) {
 			if (!uniqueId) {
 				uniqueId = "Don't call this twice without a uniqueId";
 			}
 			if (timers[uniqueId]) {
 				clearTimeout (timers[uniqueId]);
 			}
 			timers[uniqueId] = setTimeout(callback, ms);
 		};
 	})();

 	$(window).resize(function() {
 		waitForFinalEvent(function(){
 			toggleSubNav();
 			$('body').removeClass('navigation--open');
 		}, 500);
 	});


  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
    	init: function() {
        // JavaScript to be fired on all pages
        stickyNavigation();

        toggleNavigation();
        closeNavigation();
        toggleSubNav();
        toggleSidebarDropdown();
        
        openModal();
        closeModal();
        mailToPopup();

        scrollTop();

        headerImage();

        if($('#map').length) {
        	initMap();
        }


        // toggleNavigation();
        // toggleIntroduction();
        // modalForm();
        // eventPoster();
        // eventDetail();
        // instafeed();

        $('select').select2({
        	minimumResultsForSearch: Infinity
        });
    },
    finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    }
},
    // Home page
    'home': {
    	init: function() {
        // JavaScript to be fired on the home page
        // eventSlider();
    },
    finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
    }
},
    // About us page, note the change from about-us to about_us.
    'about_us': {
    	init: function() {
        // JavaScript to be fired on the about us page
    }
}
};

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
  	fire: function(func, funcname, args) {
  		var fire;
  		var namespace = Sage;
  		funcname = (funcname === undefined) ? 'init' : funcname;
  		fire = func !== '';
  		fire = fire && namespace[func];
  		fire = fire && typeof namespace[func][funcname] === 'function';

  		if (fire) {
  			namespace[func][funcname](args);
  		}
  	},
  	loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
      	UTIL.fire(classnm);
      	UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
  }
};

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
