(function($) {

	/* Magnific popup translations */

	var language = $('html').attr('lang');

	if( language == 'de-DE') {
		$.extend(true, $.magnificPopup.defaults, {
			tClose: 'Scliessen', 
			tLoading: 'Laden...', 
			gallery: {
				tPrev: 'Bisherige', 
				tNext: 'Nächster', 
				tCounter: '%curr% von %total%' 
			},
			image: {
				tError: '<a href="%url%">Das Bild</a> konnte nicht geladen werden.' 
			},
			ajax: {
				tError: '<a href="%url%">Der Inhalt</a> konnte nicht geladen werden.'
			}
		});
	}
	else if( language == 'nl-NL') {
		$.extend(true, $.magnificPopup.defaults, {
			tClose: 'Sluiten',
			tLoading: 'Bezig met laden...',
			gallery: {
				tPrev: 'Vorige',
				tNext: 'Volgende',
				tCounter: '%curr% van %total%'
			},
			image: {
				tError: '<a href="%url%">De afbeelding</a> kon niet worden geladen.'
			},
			ajax: {
				tError: '<a href="%url%">De inhoud</a> kon niet worden geladen.'
			}
		});
	}
	else {
		$.extend(true, $.magnificPopup.defaults, {
			tClose: 'Close (Esc)', 
			tLoading: 'Loading...', 
			gallery: {
				tPrev: 'Previous (Left arrow key)', 
				tNext: 'Next (Right arrow key)', 
				tCounter: '%curr% of %total%' 
			},
			image: {
				tError: '<a href="%url%">The image</a> could not be loaded.' 
			},
			ajax: {
				tError: '<a href="%url%">The content</a> could not be loaded.'
			}
		});
	}


})(jQuery); // Fully reference jQuery after this point.